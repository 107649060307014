<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiLayersTripleOutline }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-layout>
        <v-spacer></v-spacer>
        <div v-for="permission in permission" :key="permission.permission_id">
          <div v-for="usermenu in permission.usermenus" :key="usermenu.user_menu_id">
            <div v-for="user_submenu in usermenu.user_submenus" :key="user_submenu.user_submenu_id">
              <v-btn
                color="accent"
                v-show="user_submenu.create == 1 && permission.role_id == 3"
                rounded
                @click.prevent="newEstimate"
              >
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>Create New</span>
              </v-btn>
            </div>
          </div>
        </div>
        <div v-show="currentUser.role_id == 1">
          <v-btn color="accent" rounded @click.prevent="newEstimate">
            <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
            <span>Create New</span>
          </v-btn>
        </div>
      </v-layout>
    </v-toolbar>
    <v-divider class="mb-1"></v-divider>
    <v-data-table
      :headers="headers"
      :items="workflows"
      :items-per-page="10"
      :header-props="{ sortIcon: null }"
      :expanded.sync="expanded"
      show-expand
      item-key="workflow_id"
      sort-by="column_order"
      class="elevation-2"
    >
      <template v-slot:item.status_name="{ item }">
        {{ item.status_name }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click.prevent="editWorkflowModal(item)"
              dark
              class="mx-1"
              fab
              x-small
            >
              <v-icon size="20">{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on" @click.prevent="" dark class="mx-1" fab x-small>
              <v-icon size="20">{{ icons.mdiDeleteForeverOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip> -->
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="estimateHeaders"
            :items="item.estimates"
            item-key="service_id"
            :expanded.sync="expandedItem"
            show-expand
            hide-default-footer
            class="elevation-2"
          >
            <template v-slot:item.estimate_id="{ item }">
              {{ item.estimate_number }}
            </template>
            <template v-slot:item.customer_id="{ item }">
              <v-icon> {{ icons.mdiAccountTie }} </v-icon>
              {{ item.customer_name }}
            </template>
            <template v-slot:item.vehicle_id="{ item }">
              <v-icon> {{ icons.mdiCar }} </v-icon> {{ item.vehicle_name }}
            </template>
            <template v-slot:item.retail_price="{ item }">
              {{ item.retailprice }}
            </template>

            <template v-slot:item.subtotal="{ item }">
              {{ item.total }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent="editEstimate(item.estimate_id)"
                    dark
                    class="mx-1"
                    fab
                    x-small
                  >
                    <v-icon size="20">{{ icons.mdiPencilOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="error" v-bind="attrs" v-on="on" @click.prevent="" dark class="mx-1" fab x-small>
                    <v-icon size="20">{{ icons.mdiDeleteForeverOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="itemHeaders"
                  :items="item.services"
                  hide-default-footer
                  disable-sort
                  class="elevation-2"
                >
                  <template v-slot:body="{ items }">
                    <tbody v-for="service in items" :key="service.service_id">
                      <tr v-for="item in service.items" :key="item.item_id">
                        <td>{{ item.item_type }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.qty_hrs }}</td>
                        <td>{{ item.retail_price }}</td>
                        <td>{{ item.subtotal }}</td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <edit-workflowstatus
      :dialog.sync="showEditWorkflowDialog"
      :estimate="estimate"
      @open-dialog="showEditWorkflowDialog = true"
      @close-dialog="showEditWorkflowDialog = false"
    />
  </v-card>
</template>
  <script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import _ from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import EditWorkflowStatus from '@/views/workflow/Modal/EditWorkflowStatus.vue'
import {
  mdiAccountTie,
  mdiPencil,
  mdiPlus,
  mdiLayersTripleOutline,
  mdiCar,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiMessageBulleted,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiClipboardCheckOutline,
  mdiDotsVertical,
  mdiCheckCircleOutline,
  mdiMessageReplyTextOutline,
  mdiCalendarCheck,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
} from '@mdi/js'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
    'edit-workflowstatus': EditWorkflowStatus,
  },
  created: function () {
    this.getAuthorizations()
    this.getWorkflows()
    this.getEstimates()

    window.mitt.on('loadWorkflows', () => {
      this.getWorkflows()
    })
  },
  data() {
    return {
      expanded: [],
      expandedItem: [],
      workflows: [],
      estimates: [],
      Funding: [
        { person_id: '1', Amount: '130' },
        { person_id: '1', Amount: '200' },
        { person_id: '2', Amount: '350' },
        { person_id: '45', Amount: '150' },
      ],
      headers: [
        {
          text: 'Name',
          value: 'status_name',
        },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      estimateHeaders: [
        {
          text: 'Estimate No',
          value: 'estimate_id',
        },
        {
          text: 'Customer Name',
          value: 'customer_id',
        },
        {
          text: 'Vehicle Name',
          value: 'vehicle_id',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Retail Price',
          value: 'retail_price',
        },
        {
          text: 'Sub Total',
          value: 'subtotal',
        },

        { text: 'Actions', value: 'actions', sortable: false },
      ],
      itemHeaders: [
        {
          text: 'Item Type',
          value: 'item_type',
        },
        { text: 'Name', value: 'name' },
        { text: 'Quantity', value: 'qty_hrs' },
        { text: 'Retail Price', value: 'retail_price' },
        { text: 'SubTotal', value: 'subtotal' },
      ],
      showEditWorkflowDialog: false,
      estimate: {},
      permission: {},
      icons: {
        mdiAccountTie,
        mdiPencil,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiEmailOutline,
        mdiLayersTripleOutline,
        mdiCar,
        mdiMessageBulleted,
        mdiCheckboxMarkedOutline,
        mdiClipboardCheckOutline,
        mdiDotsVertical,
        mdiCheckCircleOutline,
        mdiMessageReplyTextOutline,
        mdiCalendarCheck,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiCar,
      },
    }
  },
  methods: {
    getWorkflows: function () {
      this.$store
        .dispatch('workflow/fetchWorkflows')
        .then(response => {
          this.workflows = this.fetchWorkflows
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 2,
          submenu_id: 1,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },
    newEstimate: function () {
      this.$router.push({ name: 'newestimate' })
      this.createNewEstimate()
    },
    getEstimates: function () {
      this.$store
        .dispatch('estimate/fetchEstimates')
        .then(response => {
          this.estimates = this.fetchEstimates
        })
        .catch(err => {
          console.log(err)
        })
    },

    filterEstimateByWorkflow(id) {
      return this.estimates.filter(estimate => estimate.workflow_id == id)
    },
    createNewEstimate: function () {
      this.$store
        .dispatch('estimate/createEstimate')
        .then(response => {
          this.estimate.estimate_number = this.estimateNumber.estimate_no
        })
        .catch(err => {
          console.log(err)
        })
    },
    editEstimate: function (estimate_id) {
      this.$router.push({ name: 'editestimate', params: { id: estimate_id } })
    },
    updateWorkflowStatus: function (index1, index2) {
      this.$store
        .dispatch(`workflow/updateWorkflowEstimateStatus`, {
          estimate_id: this.workflows[index1].estimates[index2].estimate_id,
          data: {
            estimate_id: this.workflows[index1].estimates[index2].estimate_id,
            workflow_id: this.workflows[index1].workflow_id,
          },
        })
        .then(response => {
          this.getWorkflows()
        })
        .catch(error => {
          console.log(error)
        })
    },
    editWorkflowModal: function (estimate) {
      this.estimate = Object.assign({}, estimate)
      this.showEditWorkflowDialog = true
    },
    formatDate(value) {
      return moment(value).format('l')
    },
  },
  computed: {
    ...mapGetters({
      fetchWorkflows: 'workflow/fetchWorkflows',
      fetchEstimates: 'estimate/fetchEstimates',
      estimateNumber: 'estimate/estimateNumber',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },

    totalSubtotal: function () {
      let sum = 0
      this.workflows.forEach(function (workflow) {
        workflow.estimates.forEach(function (estimate) {
          estimate.services.forEach(function (service) {
            service.items.forEach(function (item) {
              sum += parseFloat(item.subtotal)
            })
          })
        })
      })

      return sum.toFixed(2)
    },

    totalRetailPrice: function () {
      let sum = 0
      this.workflows.forEach(function (workflow) {
        workflow.estimates.forEach(function (estimate) {
          estimate.services.forEach(function (service) {
            service.items.forEach(function (item) {
              sum += parseFloat(item.retail_price)
            })
          })
        })
      })

      return sum.toFixed(2)
    },

    testTotal: function () {},
  },
}
</script>

<style>
</style>