<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">Edit Workflow Status</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col md="12" cols="12">
              <v-autocomplete
                v-model="estimate.workflow_id"
                :items="workflows"
                item-value="workflow_id"
                item-text="status_name"
                outlined
                color="secondary"
                dense
                chips
                deletable-chips
                small-chips
                label="Workflow Status"
                :prepend-inner-icon="icons.mdiAccountMultipleOutline"
                single-line
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click.prevent="saveWorkflowStatus">
            <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { mapGetters } from 'vuex'
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { mdiPlus, mdiInformation, mdiContentSave, mdiDeleteForeverOutline, mdiClose, mdiCash } from '@mdi/js'

export default {
  props: {
    dialog: Boolean,
    estimate: Object,
  },
  created: function () {
    this.getWorflows()
  },
  data() {
    return {
      workflows: [],
      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
      },
    }
  },
  methods: {
    getWorflows: function () {
      this.$store
        .dispatch('workflow/fetchWorkflows')
        .then(response => {
          this.workflows = this.fetchWorkflows
        })
        .catch(err => {
          console.log(err)
        })
    },

    saveWorkflowStatus: function () {
      this.$store
        .dispatch(`estimate/updateNewEstimate`, {
          estimate_id: this.estimate.estimate_id,
          data: this.estimate,
        })
        .then(response => {
          window.mitt.emit('loadWorkflows', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },

    close() {
      this.$emit('close-dialog')
    },
  },

  computed: {
    ...mapGetters({
      fetchWorkflows: 'workflow/fetchWorkflows',
    }),
  },
}
</script>
  
  <style>
</style>